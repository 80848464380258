import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  Route,
  Switch,
  Redirect,
  NavLink
} from "react-router-dom";
import "./App.css";
import firebase from "./firebase";
import icons from "./icons";
import { StyledFirebaseAuth } from "react-firebaseui";

import UserContainer from "./Components/UserContainer";
import NewUserForm from "./Components/NewUserForm";
import OrderContainer from "./Components/OrderContainer";

function SearchBox(props) {
  return (
    <div className="mainSearch">
      <img src={icons.search} alt="Search"></img>
      <input
        disabled={props.disable}
        value={props.query}
        type="text"
        onChange={props.onText}
      ></input>
    </div>
  );
}

function NewUserButton(props) {
  return (
    <div className="newUserButton">
      <button onClick={props.onClick}>Add New Customer</button>
    </div>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function AppContainer() {
  return (
    <Router>
      <App />
    </Router>
  );
}

function App() {
  const [users, updateUsers] = useState([]);
  const [usersSearched, setSearched] = useState([]);
  const [query, setQuery] = useState("");
  const [newUserFormState, showNewUserForm] = useState(false);
  const [activeCustomer, setActiveCustomer] = useState(null);
  const [userState, setUserState] = useState(false);
  const [userAccess, setUserAccess] = useState(false);
  const urlParams = useQuery();

  useEffect(() => {
    setActiveCustomer(urlParams.get("customer"));
  }, [urlParams]);

  const searchUsers = (query, toSearch) => {
    let updatedUsersSearched = toSearch.map(user => {
      if (user.name) {
        if (user.name.toLowerCase().search(query.toLowerCase()) >= 0) {
          return user;
        } else {
          return null;
        }
      } else return null;
    });
    return updatedUsersSearched;
  };

  useEffect(() => {
    if (userAccess) {
      const userRef = firebase
        .firestore()
        .collection("customers")
        .orderBy("name", "asc");
      userRef.onSnapshot(data => {
        let customerData = data.docs
          .filter(customer => customer.data().name !== "")
          .map(customer => {
            let data = customer.data();
            let id = customer.id;
            data.timestamp = data.timestamp ? data.timestamp.toDate() : undefined;
            return { ...data, id };
          });
        updateUsers(customerData);
      });
    }
  }, [userAccess]);

  //Called when logged in or out
  useEffect(() => {
    if (userState) {
      firebase.analytics().logEvent("login",{uid:firebase.auth().currentUser.uid})
      async function checkUser() {
        let currentUserDB = (
          await firebase
            .firestore()
            .collection("users")
            .doc(firebase.auth().currentUser.uid)
            .get()
        ).data();
        if (currentUserDB.access) {
          setUserAccess(true);
        } else {
          setUserAccess(false);
        }
      }
      checkUser()
    }
  }, [userState]);

  firebase.auth().onAuthStateChanged(async user => {
    setUserState(!!user);
  });

  //Update Search when query changes
  useEffect(() => {
    let escapedQuery = query.replace(/(\\)+/g, "\\\\");
    setSearched(searchUsers(escapedQuery, users));
  }, [query, users]);

  return (
    <div>
      <h1 className="mainTitle">Jack Darcy Management</h1>
      <div className="container">
        <Nav />
        <Switch>
          {userAccess ? "" : <LogIn />}
          <Route path="/orders">
            <OrderContainer customer={{}} showCustomerLink={true} />
            <button className="signOut" onClick={()=>signOut()}>Sign Out</button>
          </Route>
          <Route path="/customers/:customerId">
            <SearchBox
              query={query}
              disable={newUserFormState}
              onText={e => setQuery(e.target.value)}
            />
            <UserContainer customers={usersSearched} />
          </Route>
          <Route path="/customers/">
            <SearchBox
              query={query}
              disable={newUserFormState}
              onText={e => setQuery(e.target.value)}
            />
            {newUserFormState ? (
              ""
            ) : query.length > 3 ? (
              <NewUserButton onClick={() => showNewUserForm(true)} />
            ) : (
              ""
            )}
            {newUserFormState ? (
              <NewUserForm
                query={query}
                setQuery={setQuery}
                hide={() => showNewUserForm(false)}
              />
            ) : usersSearched ? (
              <UserContainer
                activeUser={activeCustomer}
                customers={usersSearched}
              />
            ) : (
              ""
            )}
            <button className="signOut" onClick={()=>signOut()}>Sign Out</button>
          </Route>
          <Route>
            <Redirect to="/customers" />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

const uiConfig = {
  signInFlow: "popup",
  signInSuccessUrl: "/",
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ]
};

function signOut(){
  firebase.auth().signOut()
  window.location.replace("/") //? This may not be the best going forwards
}

firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    let currentUser = firebase.auth().currentUser;
    let userInfo = {
      displayName: currentUser.displayName,
      email: currentUser.email,
      uid: currentUser.uid,
      access: false
    };
    let userDoc = await firebase
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .get();
    if (!userDoc.data()) {
      firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .set(userInfo);
    }
    return true;
  }
});

function LogIn(props) {
  if (firebase.auth().currentUser) {
    return (
      <div>
        You are not authorised
        <button onClick={() => signOut()}>Sign Out</button>
      </div>
    );
  } else {
    return (
      <div className="logIn">
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
    );
  }
}

function Nav() {
  return (
    <nav>
      <NavLink to="/customers" className="left" activeClassName="isActive">
        Customers
      </NavLink>
      <NavLink to="/orders" className="right" activeClassName="isActive">
        Orders
      </NavLink>
    </nav>
  );
}
