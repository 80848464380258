import React, { useState, useEffect } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import './NewUserForm.css'

import icons from "../icons"
import firebase from "../firebase"

//Props: query, setQuery, hideFunction

export default function NewUserForm(props){
  const [formValues, updateValues] = useState({name:props.query})
  const [alertState, setShowAlert] = useState(false);

  useEffect(()=>{
    props.setQuery(formValues.name)
  },[formValues.name, props])

  const formValueUpdated = ({ value, name }) => {
    let newValues = { ...formValues };
    newValues[name] = value;
    updateValues(newValues);
  };

  const formSubmitted = e => {
    e.preventDefault();
    if (!formValues.name || !formValues.met || !formValues.addedBy) {
      setShowAlert(true);
    } else {
      let timestamp = firebase.firestore.Timestamp.now();
      firebase.firestore().collection("customers").add({ timestamp, ...formValues })

      console.log(formValues);
      updateValues({});
      props.hide();
      firebase.analytics().logEvent("newCustomer")
    }
  };

  return (
    <form className="form" onSubmit={e => formSubmitted(e)}>
      {alertState ? <AlertDiv /> : ""}
      <label>
        <span>Name: </span>
        <TextareaAutosize
          minRows={1}
          value={props.query || ""}
          name="name"
          onChange={e => formValueUpdated(e.target)}
          placeholder="Name"
        ></TextareaAutosize>
      </label>

      <label>
        <span>Address: </span>
        <TextareaAutosize
          minRows={3}
          value={formValues.address || ""}
          name="address"
          onChange={e => formValueUpdated(e.target)}
          placeholder="Address"
        ></TextareaAutosize>
      </label>

      <label>
        <span>Email: </span>
        <TextareaAutosize
          minRows={1}
          value={formValues.email || ""}
          name="email"
          onChange={e => formValueUpdated(e.target)}
          placeholder="Email Address"
        ></TextareaAutosize>
      </label>

      <label>
        <span>Telephone: </span>
        <TextareaAutosize
          minRows={1}
          value={formValues.telephone || ""}
          name="telephone"
          onChange={e => formValueUpdated(e.target)}
          placeholder="Telephone"
        ></TextareaAutosize>
      </label>

      <label>
        <span>Met: </span>
        <select
          name="met"
          value={formValues.met || ""}
          onChange={e => formValueUpdated(e.target)}
        >
          <option value={undefined}>Where did you meet?</option>
          <option value="In Person">In Person</option>
          <option value="Phone">Phone</option>
          <option value="Online">Online</option>
          <option value="Other">Other</option>
        </select>
      </label>

      <label>
        <span>Added By: </span>
        <select
          name="addedBy"
          value={formValues.addedBy || ""}
          onChange={e => formValueUpdated(e.target)}
        >
          <option value={undefined}>Added By:</option>
          <option value="Lisa">Lisa</option>
          <option value="Steve">Steve</option>
          <option value="Amanda">Amanda</option>
          <option value="Tim">Tim</option>
        </select>
      </label>
      <div className="buttons">
        <button className="submit" formAction="submit">
          Submit
        </button>
        <button
          className="cancel"
          formAction="cancel"
          onClick={() => props.hide()}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

function AlertDiv(props) {
  return (
    <div className="alert">
      <img src={icons.alert} alt="Alert" />
      <span>You haven't entered the required information!</span>
      <img src={icons.alert} alt="Alert" />
    </div>
  );
}