import * as firebase from 'firebase/app'
import "firebase/auth"
import "firebase/firestore"
import "firebase/analytics"

let firebaseConfig = {
  apiKey: "AIzaSyCrKaUJXmR4AdZUOl0e1N_TNwIwo35RxJQ",
  authDomain: "jackdarcy-543da.firebaseapp.com",
  databaseURL: "https://jackdarcy-543da.firebaseio.com",
  projectId: "jackdarcy-543da",
  storageBucket: "jackdarcy-543da.appspot.com",
  messagingSenderId: "986706064248",
  appId: "1:986706064248:web:7d4c5e17337c6f39fa8e8d",
  measurementId: "G-LPZQ8KB2ND"
};

firebase.initializeApp(firebaseConfig)

export default firebase