import React, {useState, useEffect} from 'react'
import icons from "../icons"
import "./OrderItem.css"
import firebase from '../firebase'
import { Link } from 'react-router-dom'


//Required props: Order
export default function OrderItem(props) {
  const [orderStatus, setStatus] = useState(props.order.status)

  const getBackgroundColour = orderStatus => {
    switch (orderStatus) {
      case "Outstanding":
        return "is-outstanding";
      case "Completed":
        return "is-completed";
      default:
        return "";
    }
  };

  const deleteOrder = ()=>{
    if(window.confirm("Are you sure you want to delete this?")){
      firebase.firestore().collection("orders").doc(props.order.id).delete()
    }
  }

  const updateStatus = (newStatus)=>{
    setStatus(newStatus)
    firebase.firestore().collection("orders").doc(props.order.id).update({status:newStatus})
  }

  useEffect(()=>{
    setStatus(props.order.status)
  },[props.order.status])

  return (
    <div className={"orderItem " + getBackgroundColour(props.order.status)}>
      <div className="delete" onClick={()=>deleteOrder()}>
        <img src={icons.bin} alt="Delete"/>
      </div>
      <div className="orderDetail">
        <div className="orderDescriber">
          <img src={icons.item} alt="" /> :
        </div>
        <div className="orderItems">{props.order.items}</div>
      </div>
      <div className="orderDetail">
        <div className="orderDescriber">
          <img src={icons.specs} alt="" /> :
        </div>
        <div className="orderItems">{props.order.deliverySpecs}</div>
      </div>

      <hr className="orderDivide" />
      <div className="orderDetail">
        <div className="orderDescriber">
          <img src={icons.clock} alt="" /> :
        </div>
        <div>{props.order.timestamp.toGMTString()}</div>
      </div>
      <div className="orderDetail">
        <div className="orderDescriber">
          <img src={icons.person} alt="" /> :
        </div>
        <div>{props.order.takenby}</div>
      </div>
      <div className="orderDetail">
        <div className="orderDescriber">
          <img src={icons.location} alt="" /> :
        </div>
        <div>{props.order.origin}</div>
      </div>
      <hr className="orderDivide" />
      <div className="orderDetail">
        <div className="orderDescriber">
          <img src={icons.status} alt="" /> :
        </div>
        <select className="orderStatusDropdown" value={orderStatus} onChange={e => updateStatus(e.target.value)}>
          <option value="Outstanding">Outstanding</option>
          <option value="Processing">Processing</option>
          <option value="Completed">Completed</option>
        </select>
      </div>
      {props.showCustomerLink ? <Link to={`/customers/${props.order.customer.id}`}>Customer</Link>:""}
    </div>
  );
}