import firebase from "../firebase";
import icons from "../icons";
import TextareaAutosize from "react-textarea-autosize";
import "./OrderForm.css"

import React, { useState } from "react";


export default function OrderForm(props) {
  const [formValues, updateValues] = useState({});
  const [alertState, setShowAlert] = useState(false);

  const formValueUpdated = ({ value, name }) => {
    let newValues = { ...formValues };
    newValues[name] = value;
    updateValues(newValues);
  };

  const formSubmitted = e => {
    e.preventDefault();
    if (!formValues.items || !formValues.takenby || !formValues.origin) {
      setShowAlert(true);
    } else {
      let customer = firebase
        .firestore()
        .collection("customers")
        .doc(props.customer);
      let timestamp = firebase.firestore.Timestamp.now();

      firebase
        .firestore()
        .collection("orders")
        .add({ customer, timestamp, ...formValues });

      console.log(formValues);
      updateValues({});
      props.hide();
      firebase.analytics().logEvent("newOrder")
    }
  };

  return (
    <form className="orderForm" onSubmit={e => formSubmitted(e)}>
      {alertState ? <AlertDiv /> : ""}
      <label>
        <span>Items: </span>
        <TextareaAutosize
          minRows={3}
          value={formValues.items || ""}
          name="items"
          onChange={e => formValueUpdated(e.target)}
          placeholder="Items"
        ></TextareaAutosize>
      </label>

      <label>
        <span>Delivery Specs: </span>
        <TextareaAutosize
          minRows={3}
          value={formValues.deliverySpecs || ""}
          name="deliverySpecs"
          onChange={e => formValueUpdated(e.target)}
          placeholder="Delivery Specs"
        ></TextareaAutosize>
      </label>

      <label>
        <span>Origin: </span>
        <select
          name="origin"
          value={formValues.origin || ""}
          onChange={e => formValueUpdated(e.target)}
        >
          <option value={undefined}>Order Origin</option>
          <option value="In Person">In Person</option>
          <option value="Phone">Phone</option>
          <option value="Online">Online</option>
          <option value="Other">Other</option>
        </select>
      </label>

      <label>
        <span>Taken By: </span>
        <select
          name="takenby"
          value={formValues.takenby || ""}
          onChange={e => formValueUpdated(e.target)}
        >
          <option value={null}>Order Taker</option>
          <option value="Lisa">Lisa</option>
          <option value="Steve">Steve</option>
          <option value="Amanda">Amanda</option>
          <option value="Tim">Tim</option>
        </select>
      </label>
      <div className="buttons">
        <button className="submit" formAction="submit">
          Submit
        </button>
        <button
          className="cancel"
          formAction="cancel"
          onClick={() => props.hide()}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

function AlertDiv(props) {
  return (
    <div className="alert">
      <img src={icons.alert} alt="Alert" />
      <span>You haven't entered the required information!</span>
      <img src={icons.alert} alt="Alert" />
    </div>
  );
}