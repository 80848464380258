import React from "react";

export default function AddOrderIcon() {
	return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={{enableBackground:"new 0 0 512 512"}}
      className="newOrderSVG"
    >
      <path
        className="newOrderIconBackground"
        d="M349.999,10H84.011c-11.046,0-20,8.954-20,20v452c0,11.046,8.954,20,20,20h343.975
	c11.046,0,20-8.954,20-20V107.99L349.999,10z"
      />
      <path
        style={{fill:"#C8F5FF"}}
        d="M369.999,107.99h77.987l-97.987-97.988V87.99C349.999,99.036,358.953,107.99,369.999,107.99z"
      />
      <polygon
        className="centralPlus"
        points="367.999,220 291.999,220 291.999,144 219.999,144 219.999,220 143.999,220 143.999,292 
	219.999,292 219.999,368 291.999,368 291.999,292 367.999,292 "
      />
      <path
        d="M447.989,246c-2.631,0-5.211,1.069-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07s1.069,5.21,2.93,7.069
	c1.859,1.86,4.439,2.931,7.07,2.931c2.63,0,5.21-1.07,7.069-2.931c1.86-1.859,2.931-4.439,2.931-7.069s-1.07-5.21-2.931-7.07
	C453.199,247.069,450.619,246,447.989,246z"
      />
      <path
        d="M219.999,378h72c5.522,0,10-4.478,10-10v-66h66c5.522,0,10-4.478,10-10v-72c0-5.522-4.478-10-10-10h-66v-66
	c0-5.522-4.478-10-10-10h-72c-5.522,0-10,4.478-10,10v66h-66c-5.522,0-10,4.478-10,10v72c0,5.522,4.478,10,10,10h66v66
	C209.999,373.522,214.476,378,219.999,378z M153.999,282v-52h66c5.522,0,10-4.478,10-10v-66h52v66c0,5.522,4.478,10,10,10h66v52h-66
	c-5.522,0-10,4.478-10,10v66h-52v-66c0-5.522-4.478-10-10-10H153.999z"
      />
      <path
        d="M180.808,464c-2.63,0-5.21-1.07-7.069-2.931c-1.87-1.859-2.931-4.439-2.931-7.069s1.061-5.21,2.931-7.07
	c1.859-1.86,4.43-2.93,7.069-2.93c2.63,0,5.21,1.069,7.07,2.93c1.86,1.861,2.93,4.44,2.93,7.07s-1.069,5.21-2.93,7.069
	C186.008,462.93,183.438,464,180.808,464z"
      />
      <path
        d="M447.986,215.053c5.522,0,10-4.478,10-10V107.99c-0.008,0-0.016-0.002-0.024-0.002c-0.001-2.601-0.994-5.157-2.905-7.069
	L357.07,2.931c-1.691-1.691-3.888-2.66-6.171-2.867c-0.088-0.016-0.19-0.028-0.3-0.037c-0.036-0.002-0.071-0.009-0.107-0.011
	C350.344,0.006,350.182,0,349.999,0H84.011c-16.542,0-30,13.458-30,30v452c0,16.542,13.458,30,30,30h343.975
	c16.542,0,30-13.458,30-30V305.548c0-5.522-4.478-10-10-10s-10,4.478-10,10V482c0,5.514-4.486,10-10,10H84.011
	c-5.514,0-10-4.486-10-10v-18h64.731c5.522,0,10-4.478,10-10s-4.478-10-10-10H74.011V30c0-5.514,4.486-10,10-10h255.987v67.99
	c0,16.542,13.458,30,30,30h67.987v87.063C437.986,210.575,442.463,215.053,447.986,215.053z M369.999,97.99c-5.514,0-10-4.486-10-10
	V34.145l63.846,63.846L369.999,97.99L369.999,97.99z"
      />
    </svg>
  );
}
