import React from "react";
import UserItem from "./UserItem"
import { useParams } from "react-router-dom";
import "./UserContainer.css"


export default function UserContainer(props){
  let {customerId} = useParams()
  let uniqueView = !!customerId;
  let customers = props.customers
  
  if(customerId){
    customers = customers.filter(customer => customer.id === customerId)
  }

  customers = customers.sort((a,b)=>{
    // eslint-disable-next-line eqeqeq
    if(!a?.timestamp){
      return 1
    }else if(!b?.timestamp){
      return -1 
    }else {
      return b.timestamp - a.timestamp
    }
  })

  return customers.map(customer=>{
    if(customer){
      return <UserItem uniqueView={uniqueView} className="userItemSize" customer={customer} key={customer.id} active={(customerId === customer.id)} showInfoButton={true} showName={true}/>
    } else {
      return null
    }
  })
}


