import React, { useState, useEffect } from "react";

import firebase from "../firebase";
import OrderItem from "./OrderItem";
import "./OrderItem.css";

//Required props: customer

export default function OrderContainer(props) {
  const [orders, changeOrders] = useState([]);

  //Set up firebase subscription.
  useEffect(() => {
    const snapshotProcess = data => {
      let orders = data.docs.map(order => {
        let orderData = order.data();
        orderData.status = orderData.status || "Outstanding";
        orderData.timestamp = orderData.timestamp.toDate();
        let id = order.id;
        return { ...orderData, id };
      });
      changeOrders(orders);
    };

    if (props.customer.id) {
      let customerRef = firebase
        .firestore()
        .collection("customers")
        .doc(props.customer.id);
      let unsubscribe = firebase
        .firestore()
        .collection("orders")
        .where("customer", "==", customerRef)
        .onSnapshot(data => snapshotProcess(data));
      return function cleanup() {
        unsubscribe();
      };
    } else {
      let unsubscribe = firebase
        .firestore()
        .collection("orders")
        .onSnapshot(data => snapshotProcess(data));
      return function cleanup() {
        unsubscribe();
      };
    }
  }, [props.customer.id]);

  const orderItems = () => {
    let sortedOrders = orders.sort((a,b)=>{
      return b.timestamp - a.timestamp
    })
    return sortedOrders.map(order => {
      return <OrderItem key={order.id} order={order} showCustomerLink={props.showCustomerLink}/>;
    });
  };

  const noOrders = () => {
    return (
      <div
        className="orderItem"
        style={{ fontSize: "1.2rem", textAlign: "center" }}
      >
        There are no orders yet, add one with the plus button in the top right of this box!
      </div>
    );
  };

  return <div>{orders.length > 0 ? orderItems() : noOrders()}</div>;
}
