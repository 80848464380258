import search from "./icons/search.svg";
import address from "./icons/address.svg";
import mail from "./icons/mail.svg";
import phone from "./icons/phone.svg";
import item from "./icons/item.svg";
import clock from "./icons/clocks.svg";
import person from "./icons/person.svg";
import location from "./icons/location.svg";
import status from "./icons/status.svg";
import specs from "./icons/specs.svg"
import alert from "./icons/alert.svg"
import bin from "./icons/bin.svg"

let icons = {search,address,mail,phone,item,clock,person,location,status,specs,alert,bin}

export default icons