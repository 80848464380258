import React, { useState } from "react";
import "./Modal.css";

import NewOrder from "../icons/newOrder";

import OrderContainer from "./OrderContainer";
import OrderForm from "./OrderForm";
import UserItem from "./UserItem";

export default function Modal(props) {
  const [showForm, toggleShowForm] = useState(false);

  const showOrderButton = () => {
    if (showForm) {
      return "";
    } else {
      return (
        <div style={{cursor:"pointer"}} onClick={() => toggleShowForm(true)}>
          <NewOrder />
        </div>
      );
    }
  };

  const hideModal = () => {
    if(props.redirectOnClose){
      window.location.href = "/customers"
      document.title = "Jack Darcy Management"
    } else {
      props.hideFunction()
    }
  }

  if(props.redirectOnClose){
    document.title = `JDM: ${props.customer.name}`
  }

  return (
    <div>
      <div className="modalBackground" onClick={hideModal}></div>
      <div className="modal">
        <div className="modalBody">
          <div className="modalHeader">
            <span>{props.customer.name}</span>
            {showOrderButton()}
          </div>
          <div className="modalContent">
            {showForm ? (
              <OrderForm
                customer={props.customer.id}
                hide={() => toggleShowForm(false)}
              />
            ) : (
              <div className="modalContainer">
                {/* TODO Make this dynamic, IE, the content (OrderContainer,etc) can be passed as a prop... */}
                <UserItem
                  customer={props.customer}
                  showInfoButton={false}
                  showName={false}
                  className="item"
                />
                <hr />
                <div>
                  <OrderContainer customer={props.customer} showCustomerLink={false}/>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
