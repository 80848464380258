import React, { useState } from "react";
import "./UserItem.css";
// import firebase from "../firebase";
import icons from "../icons";
import UserOrderModal from "./Modal";

export default function UserItem(props) {
  const [showModal, updateModal] = useState(props.active);

  const modalCheck = () => {
    if (showModal) {
      return (
        <UserOrderModal
          hideFunction={() => updateModal(false)}
          customer={props.customer}
          active={showModal}
          redirectOnClose = {props.uniqueView}
        />
      );
    } else if(!props.showInfoButton){
      return ""
    } ;
  };

  return (
    <div className={props.className+" userItem"}>
      {props.showName ? <NameArea name={props.customer.name}/> : ""}
      <div className="userDetail">
        <img src={icons.mail} alt="Email" />:{props.customer.email}
      </div>
      <div className="userDetail">
        <img src={icons.phone} alt="Phone" />:{props.customer.telephone}
      </div>
      <div className="userDetail">
        <img src={icons.address} alt="Address" />:{props.customer.address}
      </div>
      <div className="userDetail">
        <img src={icons.clock} alt="Time" />:{props.customer.timestamp ? props.customer.timestamp.toGMTString() : "Unknown"}
      </div>
      {props.showInfoButton? <UserInfoButton onClick={() => updateModal(true)}/> : ""}
      {modalCheck()}
    </div>
  );
}

function UserInfoButton(props) {
  return (
    <button onClick={props.onClick} className="button infoButton">
      More Info
    </button>
  );
}

function NameArea(props){
  return(
    <div>
      <div className="userName">{props.name}</div>
      <hr className="userDivide" />
    </div>
  )
}